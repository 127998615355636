import React from 'react'
import ModelViewer from './3DModel'

const Info = () => {
  return (
    <div className='w-full bg-[#0C0A09]'>
      <div className='max-w-[1440px] mx-auto md:flex px-3 text-white'>
        <div className='w-full md:w-1/2 py-10 font-semibold'>
          <p>
            In the bustling heart of Dhaka, a young mind sparked with wonder at
            the sight of a computer screen. What began as countless hours lost
            in digital worlds soon transformed into an insatiable hunger to
            understand the magic behind the pixels.
          </p>
          <br />
          <p>
            As my fingers danced across the keyboard, I wasn't just playing
            games—I was unlocking the secrets of a digital universe. Each line
            of code became a brushstroke, painting the canvas of my future. From
            crafting simple apps to sculpting in the realm of 3D modeling, I
            pushed the boundaries of what I thought possible.
          </p>
          <br />
          <p>
            Little did I know that those late nights, fueled by curiosity and
            lit by the glow of a monitor, were shaping more than just software.
            They were molding my destiny, leading me to discover my true
            Ikigai—the intersection of passion, profession, and purpose.
          </p>
          <br />
          <p>
            Now, as a software engineer, I stand at the forefront of
            technological innovation. My journey from wide-eyed wonder to
            hands-on creation is a testament to the transformative power of
            technology. With each project, I'm not just writing code—I'm
            crafting the future, one line at a time.
          </p>
          <br />
          <p>
            In this ever-evolving digital landscape, I remain that same curious
            kid from Dhaka, always eager to explore, learn, and push the
            boundaries of what's possible. The only difference? Now, I have the
            skills to turn those dreams into reality.
          </p>
        </div>
        <div className='w-full md:w-1/2 h-[500px]'>
          <ModelViewer />
        </div>
      </div>
    </div>
  )
}

export default Info
