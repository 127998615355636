import React from 'react'
import { RouterProvider } from 'react-router-dom'
import router from './Routes/Routes.jsx'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import avatar from '../src/Assets/Images/profile.jpg'

function App() {
  return (
    <div>
      <RouterProvider router={router} />
      <FloatingWhatsApp
        phoneNumber={'+8801704952699'}
        accountName={'Md Anik Ahammed Khan'}
        avatar={avatar}
        statusMessage={'Typically replies within 15 minutes'}
        chatMessage={'Hello! 👋🏼 \n\nHow can I help you?'}
        allowClickAway={true}
      />
    </div>
  )
}

export default App
