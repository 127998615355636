import { createBrowserRouter } from 'react-router-dom'
import Main from '../Layout/Main.jsx'
import { Home } from '../Pages/Home/Home.jsx'
import { Blogs } from '../Pages/Blogs/Blogs.jsx'
import Dashboard from '../Pages/Dashboard/Dashboard.jsx'
import Login from '../Pages/Login/Login.jsx'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/blogs', element: <Blogs /> },
      { path: '/dashboard', element: <Dashboard /> },
      { path: '/login', element: <Login /> },
    ],
  },
])

export default router
