import React from 'react'
import img from './../../../Assets/Images/profile-short.jpg'
import { FiPrinter } from 'react-icons/fi'
import { FaLinkedinIn, FaGithub } from 'react-icons/fa'
import { MdMailOutline } from 'react-icons/md'

const Profile = () => {
  const handleDownloadResume = () => {
    const link = document.createElement('a')
    link.href = '/resume.pdf'
    link.download = 'Md_Anik_Ahammed_Khan_Resume.pdf'
    link.click()
  }

  const openLinkedIn = () => {
    window.open('https://www.linkedin.com/in/anikahammedkhan/', '_blank')
  }

  const openGitHub = () => {
    window.open('https://github.com/anikahammedkhan', '_blank')
  }

  const sendEmail = () => {
    window.location.href = 'mailto:anikkhan1105@gmail.com'
  }

  return (
    <div className='w-full bg-[#0C0A09] sticky top-0 z-[999]'>
      <div className='max-w-[1440px] mx-auto flex justify-between items-center p-3 text-white'>
        <div className='flex items-center gap-5'>
          <img src={img} alt='' className='w-[70px] rounded-2xl' />
          <p className='text-[22px] font-semibold'>Md Anik Ahammed Khan</p>
        </div>
        <div className='flex gap-4'>
          <FiPrinter
            className='text-[32px] cursor-pointer'
            onClick={handleDownloadResume}
          />
          <FaLinkedinIn
            className='text-[32px] cursor-pointer'
            onClick={openLinkedIn}
          />
          <MdMailOutline
            className='text-[32px] cursor-pointer'
            onClick={sendEmail}
          />
          <FaGithub
            className='text-[32px] cursor-pointer'
            onClick={openGitHub}
          />
        </div>
      </div>
    </div>
  )
}

export default Profile
