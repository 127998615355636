import React from 'react'
import OrbitComponent from '../../../Components/OrbitComponent/OrbitComponent'
import { TbArrowsExchange2 } from 'react-icons/tb'

export const HeroMain = () => {
  return (
    <div className='h-full flex justify-center items-center border w-full'>
      <div className='max-w-[1440px] mx-auto flex justify-center items-center relative h-[100vh] w-full bg-transparent'>
        <div className='z-[50] pointer-events-none w-full relative shadow-stone-300 backdrop-blur-sm bg-[linear-gradient(to_bottom_left,rgba(15,23,42,0.7),rgba(113,110,97,0.7))] h-[80vh] rounded-2xl mx-2'>
          <OrbitComponent />
          <div className='pointer-events-none absolute z-[30] select-none text-black bottom-14 w-full'>
            <div className='lg:text-[28px] md:text-[26px] text-[20px] flex items-center gap-3 text-[#109BDA] font-semibold justify-center'>
              Software Engineer <TbArrowsExchange2 />
              Code Maverick
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
