import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('./models/scene.gltf')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.scifi_computer_screen_0.geometry}
          material={materials.screen}
        />
        <mesh
          geometry={nodes.scifi_computer_computer_0.geometry}
          material={materials.computer}
        />
        <mesh
          geometry={nodes.scifi_computer_keyboard_0.geometry}
          material={materials.keyboard}
        />
        <mesh
          geometry={nodes.scifi_computer_keys_0.geometry}
          material={materials.keys}
        />
      </group>
    </group>
  )
}

useGLTF.preload('./models/scene.gltf')
