import React, { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { Model } from './Scene'

// function Model({ url }) {
//   const { scene } = useGLTF(url)
//   return <primitive object={scene} />
// }

export default function ModelViewer() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#0C0A09',
      }}
    >
      <Canvas
        camera={{ position: [18, 18, 18], fov: 15, far: 500 }}
        frameloop='demand'
        shadows
      >
        <directionalLight
          castShadow
          position={[500, 500, 500]}
          intensity={3}
          color={0xffffff}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={1000}
          shadow-camera-left={-20}
          shadow-camera-right={20}
          shadow-camera-top={20}
          shadow-camera-bottom={-20}
        />
        <ambientLight intensity={5} color={0xaaaaaa} />
        <Suspense fallback={null}>
          <Model />
        </Suspense>
        <OrbitControls />
      </Canvas>
    </div>
  )
}
