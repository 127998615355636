import React from 'react'
import { Hero } from './Components/Hero.jsx'
import Profile from './Components/Profile.jsx'
import Info from './Components/Info.jsx'
import Work from './Components/Work.jsx'

export const Home = () => {
  return (
    <div className='relative'>
      <Hero />
      <Profile />
      <Info />
      <Work />
    </div>
  )
}
