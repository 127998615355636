import React, { lazy, Suspense } from 'react'
import { HeroMain } from './HeroMain'

const LazyParticles = lazy(() => import('react-tsparticles'))

export const Hero = React.memo(() => {
  return (
    <div className='w-full h-[100vh]'>
      <HeroMain />
      <div className='overflow-hidden'>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyParticles
            options={{
              style: {
                position: 'absolute',
                width: '100%',
                height: '100vh',
                zIndex: -1,
                top: 0,
                left: 0,
              },
              particles: {
                number: {
                  value: 80,
                  limit: 300,
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                },
                color: {
                  value: [
                    '#06b6d4',
                    '#3b82f6',
                    '#f87171',
                    '#fbbf24',
                    '#34d399',
                    '#f472b6',
                    '#10b981',
                    '#4b5563',
                    '#d69e2e',
                    '#f59e0b',
                    '#d97706',
                    '#d9463d',
                    '#d53f8c',
                    '#6d28d9',
                    '#4c1d95',
                    '#6b7280',
                    '#374151',
                    '#1f2937',
                    '#111827',
                  ],
                },
                shape: {
                  type: 'polygon',
                  stroke: {
                    width: 0,
                    color: '#06b6d4',
                  },
                  polygon: {
                    nb_sides: 5,
                  },
                  image: {
                    src: 'img/github.svg',
                    width: 100,
                    height: 100,
                  },
                },
                opacity: {
                  value: 0.5,
                  random: true,
                  anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 5,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 40,
                    size_min: 0.1,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: true,
                  distance: 150,
                  color: {
                    value: ['#06b6d4'],
                  },
                  opacity: 0.4,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 2,
                  direction: 'none',
                  random: false,
                  straight: false,
                  out_mode: 'bounce',
                  bounce: false,
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: 'canvas',
                events: {
                  onhover: {
                    enable: true,
                    mode: 'grab',
                  },
                  onclick: {
                    enable: true,
                    mode: 'repulse',
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
              fps_limit: 120,
              background: {
                maxheight: '100%',
                color: '#0C0A09',
                image: '',
                position: '50% 50%',
                repeat: 'no-repeat',
                size: 'cover',
              },
            }}
          />
        </Suspense>
      </div>
    </div>
  )
})
