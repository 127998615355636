import React, { memo } from 'react'
import styles from './OrbitComponent.module.scss'
import profile from './../../Assets/Images/profile.jpg'
import {
  reactIcon,
  nextjsIcon,
  nodejsIcon,
  reduxIcon,
  jsIcon,
  tsIcon,
  awsIcon,
  dockerIcon,
  expressIcon,
  graphqlIcon,
  jestIcon,
  mongodbIcon,
  postgressIcon,
  prismaIcon,
  sqlIcon,
} from './iconImports.ts'

const OrbitIcon = memo(({ src, alt }) => (
  <img src={src} alt={alt} className={styles.orbitIcon} loading='lazy' />
))

const OrbitRing = memo(({ icons, ringClass }) => (
  <ul className={`${styles.ring} ${styles[ringClass]}`}>
    {icons.map((icon, index) => (
      <li key={index}>
        <OrbitIcon src={icon} alt={`Orbit Icon ${index}`} />
      </li>
    ))}
  </ul>
))

const OrbitComponent = memo(() => {
  const innerIcons = [
    reactIcon,
    nodejsIcon,
    nextjsIcon,
    reduxIcon,
    tsIcon,
    jsIcon,
  ]
  const outerIcons = [
    awsIcon,
    dockerIcon,
    expressIcon,
    graphqlIcon,
    jestIcon,
    mongodbIcon,
    postgressIcon,
    prismaIcon,
    sqlIcon,
  ]

  return (
    <div className={styles.orbit}>
      <div className={styles.orbitWrap}>
        <div className={styles.orbitCenter}>
          <img
            src={profile}
            alt='Profile'
            className={styles.orbitCenterIcon}
            loading='lazy'
          />
        </div>
        <OrbitRing icons={outerIcons} ringClass='ring0' />
        <OrbitRing icons={innerIcons} ringClass='ring1' />
      </div>
    </div>
  )
})

export default OrbitComponent
