import React from 'react'
import bg from './../../Assets/Images/footer.png'

export const Footer = () => {
  return (
    <div className='bg-[#0C0A09] h-[300px] w-full py-5 relative text-white'>
      <img
        src={bg}
        alt=''
        className='absolute transform -translate-y-1/2 -translate-x-1/2 top-1/3 left-1/2 opacity-50'
      />
      <div className='text-center z-[999]'>
        <p>THAT'S ALL, FOLKS!</p>
        <p
          style={{
            fontFamily: "'Satisfy', cursive",
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '3rem',
            color: '#0EA5E9',
            textAlign: 'center',
            margin: '20px 0',
          }}
        >
          Anik Ahammed Khan
        </p>
        <p>
          All logos, trademarks, and brandnames are the property of their
          respective owners.
        </p>
        <p>Hand-coded with 100% human efforts, zero AI.</p>
      </div>
    </div>
  )
}
