import React from 'react'
import { Outlet } from 'react-router-dom'
import { Footer } from '../Components/Footer/Footer.jsx'
// import Navbar from '../Components/Navbar/Navbar.jsx'

const Main = () => {
  return (
    <div className='h-fit'>
      <Outlet></Outlet>
      <Footer></Footer>
    </div>
  )
}

export default Main
